import {Box, Typography, useMediaQuery} from "@mui/material";
import FeatureMenu from "./FeatureMenu";
import {useEffect, useState} from "react";
import ControlFeatures from "./ControlFeatures";
import {useTranslation} from "react-i18next";

const isIOSDevice = () => {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPad',
        'iPhone',
    ].includes(navigator.platform)
};

const ControlSection = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const {t} = useTranslation();

    const [isIOS, setIsIOS] = useState(false);

    useEffect(() => {
        setIsIOS(isIOSDevice());
    }, []);

    return(
        <Box sx={{
            position:'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent:isMobile? 'flex-end' : 'center',
            backgroundImage:'url("/images/sezione-control-bkg.png")',
            backgroundPosition:'center',
            backgroundSize:'cover',
            //backgroundColor:'#fff',
            overflowX: 'hidden',
            width: '100vw',
            height:'100vh'
        }}>
            <Typography variant={isMobile ? 'h4' : 'h3'} mb={5} p={2} textAlign={'center'}>
                {t("control.title")} 🎬
            </Typography>
            {isMobile &&
                <video
                    src={isIOS ? `/videos/REGISTA_iPhone.mov` : `/videos/REGISTA_iPhone.webm`}
                    autoPlay
                    loop
                    muted
                    playsInline
                    style={{
                        width: '30vh',
                        height: "auto",
                        position: "relative",
                        marginTop:"10%",
                        marginBottom:'10%'
                    }}
                />
            }
            <ControlFeatures/>
        </Box>
    )
}

export default ControlSection;