import './App.css';
import {CssBaseline, ThemeProvider} from "@mui/material";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {LanguageRoute} from "./components/LanguageRoute";
import {pleshDark} from "./theme/pleshDark";
import Home from "./pages/Home";
import './services/i18n.js';
import {Layout} from "./components/Layout";
import Portfolio from "./pages/Portfolio";
import Features from "./pages/Features";
import Contact from "./pages/Contact";
import {Suspense} from "react";
import Loader from "./components/Loader";

function App() {
    const helmetContext = {};

    return (
        <HelmetProvider context={helmetContext}>
            <Helmet>
                <title>2ndStage - Attiva la tua Audience</title>
                <link rel="alternate" hrefLang="it" href="https://2ndstage.app"/>

                <meta lang="it" name="description"
                      content="2ndStage è la tecnologia interattiva che permette allo spettatore di essere protagonista dello show, utilizzando il proprio smartphone. Coinvolgi e monetizza il tuo pubblico con un’esperienza unica e straordinaria."/>
                <meta lang="it" itemProp="name" content="2ndStage - Attiva la tua Audience"/>
                <meta lang="it" itemProp="description"
                      content="2ndStage è la tecnologia interattiva che permette allo spettatore di essere protagonista dello show, utilizzando il proprio smartphone. Coinvolgi e monetizza il tuo pubblico con un’esperienza unica e straordinaria."/>
                <meta lang="it" itemProp="image" content="https://2ndstage.app/preview.jpg"/>
                <meta lang="it" property="og:url" content="https://2ndstage.app"/>
                <meta lang="it" property="og:type" content="website"/>
                <meta lang="it" property="og:title" content="2ndStage - Attiva la tua Audience"/>
                <meta lang="it" property="og:description"
                      content="2ndStage è la tecnologia interattiva che permette allo spettatore di essere protagonista dello show, utilizzando il proprio smartphone. Coinvolgi e monetizza il tuo pubblico con un’esperienza unica e straordinaria."/>
                <meta lang="it" property="og:image"
                      content="https://2ndstage.app/preview.jpg"/>
                <meta lang="it" name="twitter:card" content="summary_large_image"/>
                <meta lang="it" name="twitter:title"
                      content="2ndStage - Attiva la tua Audience"/>
                <meta lang="it" name="twitter:description"
                      content="2ndStage è la tecnologia interattiva che permette allo spettatore di essere protagonista dello show, utilizzando il proprio smartphone. Coinvolgi e monetizza il tuo pubblico con un’esperienza unica e straordinaria."/>
                <meta lang="it" name="twitter:image"
                      content="https://2ndstage.app/preview.jpg"/>
                {/*Font preload*/}
                <link rel="preload" href="/fonts/NNForeverGroteskSTD-Light.woff2" as="font" type="font/woff2"
                      crossOrigin="anonymous"/>
                <link rel="preload" href="/fonts/NNForeverGroteskSTD-Normal.woff2" as="font" type="font/woff2"
                      crossOrigin="anonymous"/>
                <link rel="preload" href="/fonts/NNForeverGroteskSTD-Bold.woff2" as="font" type="font/woff2"
                      crossOrigin="anonymous"/>

                <link rel="preload" href="/fonts/ProtoGrotesk-Bold.woff2" as="font" type="font/woff2"
                      crossOrigin="anonymous"/>
                <link rel="preload" href="/fonts/ProtoGrotesk-Regular.woff2" as="font" type="font/woff2"
                      crossOrigin="anonymous"/>

                <link rel="preload" href="/fonts/Poppins-Regular.ttf" as="font" type="font/ttf"
                      crossOrigin="anonymous"/>
                <link rel="preload" href="/fonts/Poppins-Medium.ttf" as="font" type="font/ttf" crossOrigin="anonymous"/>
                <link rel="preload" href="/fonts/Poppins-ExtraBold.ttf" as="font" type="font/ttf"
                      crossOrigin="anonymous"/>

                <link rel="preload" href="/videos/video-main.webm" as="video" type="video/webm"
                      crossOrigin="anonymous"/>

                <link rel="preload" href="/videos/cards/brainstorming.webm" as="video" type="video/webm"
                      crossOrigin="anonymous"/>
                <link rel="preload" href="/videos/cards/musicstorm-AI.webm" as="video" type="video/webm"
                      crossOrigin="anonymous"/>
                <link rel="preload" href="/videos/cards/Q&A.webm" as="video" type="video/webm"
                      crossOrigin="anonymous"/>
                <link rel="preload" href="/videos/cards/selfie-AI.webm" as="video" type="video/webm"
                      crossOrigin="anonymous"/>
                <link rel="preload" href="/videos/cards/summary-AI.webm" as="video" type="video/webm"
                      crossOrigin="anonymous"/>
                <link rel="preload" href="/videos/cards/wordstorm-AI.webm" as="video" type="video/webm"
                      crossOrigin="anonymous"/>

                <link rel="preload" href="/videos/QR_scan.mov" as="video" type="video/quicktime"
                      crossOrigin="anonymous"/>
                <link rel="preload" href="/videos/REGISTA_iPad.mov" as="video" type="video/quicktime"
                      crossOrigin="anonymous"/>
                <link rel="preload" href="/videos/REGISTA_iPhone.mov" as="video" type="video/quicktime"
                      crossOrigin="anonymous"/>

                <link rel="preload" href="/videos/features/SONDAGGI_iPhone.mov" as="video" type="video/quicktime"
                      crossOrigin="anonymous"/>
                <link rel="preload" href="/videos/features/SONDAGGI_iPad.mov" as="video" type="video/quicktime"
                      crossOrigin="anonymous"/>

            </Helmet>
            <ThemeProvider theme={pleshDark}>
                <CssBaseline/>
                <Suspense fallback={<Loader/>}>
                    <BrowserRouter>
                        <div className="App">
                            <Routes>
                                {/* Rotte senza parametro lingua */}
                                <Route path="/" element={<Layout/>}>
                                    <Route index element={<Home/>}/>
                                    <Route path="portfolio" element={<Portfolio/>}/>
                                    <Route path="features" element={<Features/>}/>
                                    <Route path="contattaci" element={<Contact/>}/>
                                </Route>
                                <Route
                                    path="/:lang"
                                    element={
                                        <LanguageRoute>
                                            <Layout/>
                                        </LanguageRoute>
                                    }
                                >
                                    <Route index element={<Home/>}/>
                                    <Route path="portfolio" element={<Portfolio/>}/>
                                    <Route path="features" element={<Features />} />
                                    <Route path="contattaci" element={<Contact  />} />
                                </Route>
                            </Routes>
                        </div>
                    </BrowserRouter>
                </Suspense>
            </ThemeProvider>
        </HelmetProvider>
    );
}

export default App;
