import React, {useEffect, useRef, useState} from "react";
import {
    AppBar,
    Box,
    Toolbar,
    IconButton,
    Typography,
    Button,
    Drawer,
    List,
    ListItem,
    ListItemText,
    useScrollTrigger, Slide
} from "@mui/material";
import { Menu as MenuIcon, Language } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { LangSwitcher } from "./LangSwitcher";

import {useTranslation} from "react-i18next";
import {NavLink, useLocation, useParams} from "react-router-dom";
import PropTypes from 'prop-types';

import { HashLink } from 'react-router-hash-link';

function HideOnScroll(props) {
    const {children, window} = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

export const Header = (props) => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const {t} = useTranslation()

    const [appBarHeight, setAppBarHeight] = useState(0);
    const appBarRef = useRef(null);

    const [selectedButton, setSelectedButton] = useState("homepage");

    const { lang } = useParams();
    const location = useLocation(); // Ottieni il path corrente
    const getPath = (path) => (lang ? `/${lang}${path}` : path);

    useEffect(() => {
        if (appBarRef.current) {
            setAppBarHeight(appBarRef.current.offsetHeight);
        }
    }, [appBarRef]);

    useEffect(() => {
        const currentPath = location.pathname;
        if (currentPath.includes('/portfolio')) {
            setSelectedButton("portfolio");
        } else if (currentPath.includes('/features')) {
            setSelectedButton("features");
        } else if (currentPath.includes('/contattaci')) {
            setSelectedButton("contattaci");
        } else {
            setSelectedButton("homepage");
        }
    }, [location]);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleButtonClick = (buttonName) => {
        setSelectedButton(buttonName);
    };

    const drawer = (
        <Box sx={{ width: '100%', backgroundColor:selectedButton==='portfolio' ? 'transparent' : "primary.main"}}>
            <List
                sx={{
                    '& .MuiListItemText-primary': {
                        color: '#fff', // Imposta il colore del testo su bianco
                    },
                    '& .MuiListItem-root': {
                        color: '#fff', // Imposta il colore per l'icona o altri elementi
                    },
                }}
            >
                <ListItem>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
                        <LangSwitcher />
                        <Button component={NavLink} to={getPath('/contattaci')} variant="outlined" color="inherit"
                                onClick={() => {
                                    handleButtonClick('contattaci')
                                    handleDrawerToggle();}}
                        >
                            {t('header.contattaci')}
                        </Button>
                    </div>

                </ListItem>
                <ListItem>
                    <Button variant="mobile-header"
                            key={"homepage"}
                            component={NavLink}
                            to={getPath('/')}
                            sx={{
                                fontWeight: selectedButton === "homepage" ? '700' : '400',
                            }}
                            onClick={() => {
                                handleDrawerToggle();
                                handleButtonClick("homepage")
                            }}
                    >
                        Homepage
                    </Button>
                </ListItem>
                <ListItem>
                    <Button variant="mobile-header"
                            key={"portfolio"}
                            component={NavLink}
                            to={getPath('/portfolio')}
                            sx={{
                                fontWeight: selectedButton === "portfolio" ? '700' : '400',
                            }}
                            onClick={() => {
                                handleDrawerToggle();
                                handleButtonClick("portfolio")
                            }}
                    >
                        Portfolio
                    </Button>
                </ListItem>
                <ListItem>
                    <Button variant="mobile-header"
                            key={"features"}
                            component={HashLink}
                            smooth
                            to={getPath('/#features')}
                            sx={{
                                fontWeight: selectedButton === "features" ? '700' : '400',
                            }}
                            onClick={() => {
                                handleDrawerToggle();
                                handleButtonClick("features")
                            }}
                    >
                        Features
                    </Button>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <HideOnScroll {...props}>
        <AppBar ref={appBarRef}
                sx={{
                    position:'fixed',
                    backgroundColor:selectedButton==='portfolio' ? 'transparent' : "primary.main",
                    height:'75px',
                    zIndex: (theme) => theme.zIndex.appBar,
                    transition: 'background-color 0.3s ease',
                    '&:hover': {
                        backgroundColor: selectedButton === 'portfolio' ? 'transparent' : 'primary.main', // Forza trasparenza su hover
                    },
        }}>
            <Toolbar sx={{padding:'16px'}}>
                {/* Logo */}
                <img src={'/logo-2ndstage.svg'} alt={'logo'}/>

                {/* Desktop Menu */}
                {!isMobile && (
                    <Box gap={'40px'} sx={{display: "flex", alignItems: "center", flexGrow: 1, justifyContent: "center"}}>
                        <Button variant="header"
                                key={"homepage"}
                                component={NavLink}
                                to={getPath('/')}
                                sx={{
                                    fontWeight: selectedButton === "homepage" ? '700' : '400',
                                    borderBottom: selectedButton === "homepage" ? '1px solid #f1417a' : 'none'
                                }}
                                onClick={() => handleButtonClick("homepage")}
                        >
                            Homepage
                        </Button>
                        <Button variant="header"
                                key={"portfolio"}
                                component={NavLink}
                                to={getPath('/portfolio')}
                                sx={{
                                    fontWeight: selectedButton === "portfolio" ? '700' : '400',
                                    borderBottom: selectedButton === "portfolio" ? '1px solid #f1417a' : 'none'
                                }}
                                onClick={() => handleButtonClick("portfolio")}
                        >
                            Portfolio
                        </Button>
                        <Button variant="header"
                                key={"features"}
                                component={HashLink}
                                smooth
                                to={getPath('/#features')}
                                sx={{
                                    fontWeight: selectedButton === "features" ? '700' : '400',
                                    borderBottom: selectedButton === "features" ? '1px solid #f1417a' : 'none'
                                }}
                        >
                            Features
                        </Button>
                    </Box>
                )}

                {/* Right Side (Contattaci + LangSwitcher) */}
                {!isMobile && (
                    <Box gap={'20px'} sx={{display: "flex", alignItems: "center"}}>
                        <Button component={NavLink} to={getPath('/contattaci')} variant="outlined" color="inherit"
                                onClick={() => {
                                    handleButtonClick('contattaci')}}
                        >
                            {t('header.contattaci')}
                        </Button>
                        <LangSwitcher/>
                    </Box>
                )}

                {/* Mobile Menu Icon */}
                {isMobile && (
                    <IconButton sx={{marginLeft:'auto'}} edge="end" onClick={handleDrawerToggle}>
                        <MenuIcon sx={{
                            color: mobileOpen ? 'accent.main' : 'inherit',
                        }}/>
                    </IconButton>
                )}
            </Toolbar>

            {/* Drawer for Mobile */}
            <Drawer
                anchor="top"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true,
                    BackdropProps: {
                        invisible: true
                    }
                }}

                sx={{
                    zIndex: (theme) => theme.zIndex.appBar - 1,  // Posiziona il Drawer sotto l'AppBar
                }}
                PaperProps={{
                    sx: {
                        marginTop: `${appBarHeight}px`, // Offset dinamico
                        height: `fit-content`,
                        borderRadius:'0px',
                        zIndex:'1'
                    },
                }}
            >
                {drawer}
            </Drawer>
        </AppBar>
        </HideOnScroll>
    );
};
