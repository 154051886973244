import { Box, useMediaQuery } from "@mui/material";
import FeatureMenu from "./FeatureMenu";
import { useState, useEffect } from "react";

import featureSectionStyle from "../stylesheets/FeatureSection.module.css";

const isIOSDevice = () => {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPad',
        'iPhone',
    ].includes(navigator.platform)
};

const FeatureSection = () => {
    const isMobile = useMediaQuery("(max-width:600px)");
    const [currentFeature, setCurrentFeature] = useState(null); // Feature attualmente visibile
    const [isFading, setIsFading] = useState(false); // Gestisce la transizione

    const [isIOS, setIsIOS] = useState(false);

    useEffect(() => {
        setIsIOS(isIOSDevice());
    }, []);

    const handleFeatureSelect = (feature) => {
        if (feature === currentFeature) return; // Evita transizioni inutili

        setIsFading(true); // Attiva il fade-out
        setTimeout(() => {
            setCurrentFeature(feature); // Cambia feature
            setIsFading(false); // Completa il fade-in
        }, 500); // Durata della transizione
    };

    const iPhonePath = currentFeature?.asset || 'PLACEHOLDER';

    console.info(iPhonePath);

    return (
        <Box
            id="features"
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#fff",
                overflowX: "hidden",
                width: "100vw",
                position: "relative",
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    padding: isMobile ? "2%" : "5%",
                    paddingTop: "5%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                }}
            >
                <FeatureMenu onSelect={handleFeatureSelect} />
            </Box>

            <Box
                sx={{
                    height: isMobile ? "35vh" : "65vh",
                    mt: isMobile ? "10vh" : "0",
                    mb: isMobile ? "10vh" : "5vh",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    justifyContent: "center",
                    width: "100%",
                }}
            >
                {isIOS ?
                    <video
                        className={isFading ? featureSectionStyle.fadeOutL : featureSectionStyle.fadeIn}
                        src={
                            currentFeature?.asset
                                ? `/videos/features/${currentFeature.asset}_iPhone.mov`
                                : "/videos/features/PLACEHOLDER_iPhone.webm"
                        }
                        autoPlay
                        loop
                        muted
                        playsInline
                        style={{
                            width: isMobile ? "120%" : "60%",
                            height: "auto",
                            position: isMobile ? "absolute" : "absolute",
                            left: isMobile ? "-40%" : "-5%",
                        }}
                    />
                    :
                    <video
                        className={isFading ? featureSectionStyle.fadeOutL : featureSectionStyle.fadeIn}
                        src={
                            currentFeature?.asset
                                ? `/videos/features/${currentFeature.asset}_iPhone.webm`
                                : "/videos/features/PLACEHOLDER_iPhone.webm"
                        }
                        autoPlay
                        loop
                        muted
                        playsInline
                        style={{
                            width: isMobile ? "45%" : "22%",
                            height: "auto",
                            position: isMobile ? "absolute" : "relative",
                            left: isMobile ? "-4%" : "unset",
                        }}
                    />
                }
                {/*<video
                    className={isFading ? featureSectionStyle.fadeOutL : featureSectionStyle.fadeIn}
                    autoPlay
                    loop
                    muted
                    playsInline
                    style={{
                        width: isMobile ? "100%" : "22%",
                        height: "auto",
                        position: isMobile ? "absolute" : "absolute",
                        left: isMobile ? "-4%" : "-5%",
                    }}
                >
                    <source src={`/videos/features/${iPhonePath}_iPhone.webm`} type="video/webm"/>
                    <source src={`/videos/features/${iPhonePath}_iPhone.mov`} type="video/quicktime"/>
                </video>*/}

                {isIOS ?
                    <video
                        className={isFading ? featureSectionStyle.fadeOutR : featureSectionStyle.fadeIn}
                        src={
                            currentFeature?.asset
                                ? `/videos/features/${currentFeature.asset}_iPad.mov`
                                : "/videos/features/PLACEHOLDER_iPad.webm"
                        }
                        autoPlay
                        loop
                        muted
                        playsInline
                        style={{
                            width: isMobile ? "90%" : "65%",
                            height: "auto",
                            marginLeft: isMobile ? "25%" : "20%",
                            marginBottom: isMobile ? "10%" : 0,
                        }}
                    />
                    :
                    <video
                        className={isFading ? featureSectionStyle.fadeOutR : featureSectionStyle.fadeIn}
                        src={
                            currentFeature?.asset
                                ? `/videos/features/${currentFeature.asset}_iPad.webm`
                                : "/videos/features/PLACEHOLDER_iPad.webm"
                        }
                        autoPlay
                        loop
                        muted
                        playsInline
                        style={{
                            width: isMobile ? "70%" : "50%",
                            height: "auto",
                            marginLeft: isMobile ? "25%" : 0,
                            marginBottom: isMobile ? "10%" : 0,
                        }}
                    />
                }

            </Box>
        </Box>
    );
};

export default FeatureSection;
