import {Box, Typography, useMediaQuery} from "@mui/material";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

const isIOSDevice = () => {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPad',
        'iPhone',
    ].includes(navigator.platform)
};

const SempliceSection = () => {
    const isMobile = useMediaQuery('(max-width:600px)');

    const {t} = useTranslation();

    const [isIOS, setIsIOS] = useState(false);

    useEffect(() => {
        setIsIOS(isIOSDevice());
    }, []);

    return(
        <Box sx={{
            position:'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent:'flex-start',
            backgroundColor:'#fff',
            width: '100vw',
            height: isMobile ? 'fit-content' : '80vh',
            overflowX: 'hidden',
            overflow:'visible'
        }}>
            <video src={isIOS ? '/videos/QR_scan.mov' : '/videos/QR_scan.webm'} style={{
                position: 'absolute',
                zIndex: '2',
                width: isMobile ? '30vh' : '40vw',
                height: 'auto',
                right: isMobile ? '0%' : '10%',
                bottom: isMobile ? '-4vh' : '-10vh'
            }}
                   autoPlay
                   loop
                   muted
                   playsInline
            >
                <source src={'/videos/QR_scan.webm'} type="video/webm"/>
            </video>
            <Box sx={{
                position:'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                height:'100%',
                justifyContent:'center',
                backgroundColor:'#fff',
                overflowX: 'hidden',
                padding:'5%',
                width:isMobile ? '60%' : '100%',
                mt: isMobile ? '5%' : 0,
                mb: isMobile ? '5%' : '5%'
            }}>
                <Typography variant={isMobile ? 'h5' : 'h3'} color={'#000'}>{t(`semplice.title`)} 🤝</Typography>
                <Box sx={{
                    width:isMobile ? '100%' : '40%',
                    marginTop:'5%'
                }}>
                    <Typography variant={'body'} color={'#000'}>
                        {t(`semplice.description`)}
                    </Typography>
                </Box>
                {!isMobile &&
                    <Box sx={{
                    width:'50%',
                    marginTop:'5%',
                    display:'flex',
                    flexDirection:'row',
                    alignItems:'center',
                    columnGap:'20px'
                    }}>
                        <img src={'/images/verify-icon.png'} style={{width:'34px'}} />
                        <Typography variant={'body'} color={'#000'}>
                            {t(`semplice.bullet_1`)}
                        </Typography>
                    </Box>
                }
                {!isMobile &&
                    <Box sx={{
                        width:'50%',
                        marginTop:'2%',
                        display:'flex',
                        flexDirection:'row',
                        alignItems:'center',
                        columnGap:'20px'
                    }}>
                        <img src={'/images/verify-icon.png'} style={{width:'34px'}} />
                        <Typography variant={'body'} color={'#000'}>
                            {t(`semplice.bullet_2`)}
                        </Typography>
                    </Box>
                }
            </Box>
        </Box>
    )
}

export default SempliceSection;