import {Box, Button, Typography, useMediaQuery} from "@mui/material";
import AwardCard from "./AwardCard";
import {useTheme} from "@mui/material/styles";
import {NavLink, useLocation, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";


const AwardsSection = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const {t} = useTranslation();

    const { lang } = useParams();
    const location = useLocation(); // Ottieni il path corrente
    const getPath = (path) => (lang ? `/${lang}${path}` : path);

    return(
        <Box sx={{
            position:'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent:'center',
            background: 'linear-gradient(360deg, #F95F43 0%, #F1417A 100%)',
            overflowX: 'hidden',
            width: '100vw',
            height:isMobile ? '75vh' : '100vh',
            overflow:'hidden'
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent:'center',
                gap:isMobile ? '10px' : '40px'
            }}>
                <AwardCard title={'Bea: best digital platform'} year={'2021'} imgUrl={'/images/award-img-1.png'} />
                <AwardCard title={'Bea: best digital platform'} year={'2022'} imgUrl={'/images/award-img-2.png'} />
                <AwardCard title={'Bea: best digital platform'} year={'2023'} imgUrl={'/images/award-img-3.png'} />
            </Box>
            <Typography variant={'h6'} sx={{marginTop:isMobile ? '20%' : '5%'}} fontWeight={'normal'}>
                {t(`awards.title`)} 😄
            </Typography>
            <Button
                component={NavLink}
                to={getPath('/contattaci')}
                variant="empty"
                color="primary"
                type="submit"
                sx={{marginTop: '1rem'}}
            >
                {t(`awards.action`)}
            </Button>

        </Box>
    )
}

export default AwardsSection;