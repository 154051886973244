import {useEffect, useRef, useState} from "react";
import controlFeaturesStyle from '../stylesheets/ControlFeatures.module.css';
import {Typography, useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import featureSectionStyle from "../stylesheets/FeatureSection.module.css";

const isIOSDevice = () => {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPad',
        'iPhone',
    ].includes(navigator.platform)
};

const ControlFeatures = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Definisci 'sm' come breakpoint mobile

    const {t} = useTranslation();
    const [isIOS, setIsIOS] = useState(false);

    useEffect(() => {
        setIsIOS(isIOSDevice());
    }, []);


    const phrases = [
        t('control.report'),
        t('control.blocchi'),
        t('control.domande'),
        t('control.home'),
        t('control.risposta'),
        t('control.play'),
        t('control.qr')
    ];

    const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
    const phraseRefs = useRef([]); // Array di ref per ogni frase
    const wrapperRef = useRef(null); // Ref per il contenitore delle frasi

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        }, 3000); // Cambia frase ogni 3 secondi

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if (isMobile && wrapperRef.current && phraseRefs.current[currentPhraseIndex]) {
            const wrapper = wrapperRef.current;
            const currentPhrase = phraseRefs.current[currentPhraseIndex];

            // Dimensioni e posizione del wrapper
            const wrapperRect = wrapper.getBoundingClientRect();

            // Dimensioni e posizione della frase corrente
            const phraseRect = currentPhrase.getBoundingClientRect();

            // Calcola il centro del wrapper e della frase
            const wrapperCenter = wrapperRect.width / 2;
            const phraseCenter = phraseRect.left + phraseRect.width / 2 - wrapperRect.left;

            // Calcola lo spostamento necessario
            const translateX = phraseCenter - wrapperCenter;

            // Applica la trasformazione
            wrapper.style.transition = 'transform 0.5s ease-in-out'; // Transizione fluida
            wrapper.style.transform = `translateX(-${translateX}px)`;
        }
    }, [currentPhraseIndex, isMobile]);

    return (
        <div className={controlFeaturesStyle.container}>

            <div className={controlFeaturesStyle.wrapper} ref={wrapperRef}>
                {!isMobile && <video
                    src={isIOS ? `/videos/REGISTA_iPad.mov` : `/videos/REGISTA_iPad.webm`}
                    autoPlay
                    loop
                    muted
                    playsInline
                    style={{
                        width: "35vw",
                        height: "auto",
                        position: isMobile ? "absolute" : "absolute",
                        left: "40%",
                        top: '5%'
                    }}
                />}

                {isMobile && phrases.concat(phrases).map((phrase, index) => (
                    <>
                        <div
                            key={index}
                            ref={(el) => {
                                phraseRefs.current[index] = el;
                            }}
                            className={controlFeaturesStyle.phraseBox}
                            data-position={index === currentPhraseIndex ? "current" : undefined}
                        >
                            <img
                                className={controlFeaturesStyle.icon}
                                src={'/images/checkbox.svg'}
                                alt={'icon'}
                                data-current={index === currentPhraseIndex ? "true" : "false"}
                            />
                            <Typography variant={"body1"}>
                                {phrase}
                            </Typography>
                        </div>
                    </>

                ))

                }
                {!isMobile && phrases.map((phrase, index) => (
                    <div
                        key={index}
                        ref={(el) => {
                            phraseRefs.current[index] = el;
                        }}
                        className={controlFeaturesStyle.phraseBox}
                        data-position={index === currentPhraseIndex ? "current" : undefined}
                    >
                        <img
                            className={controlFeaturesStyle.icon}
                            src={'/images/checkbox.svg'}
                            alt={'icon'}
                            data-current={index === currentPhraseIndex ? "true" : "false"}
                        />
                        <Typography variant={"body1"}>
                            {phrase}
                        </Typography>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ControlFeatures;
