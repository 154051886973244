import React, {useEffect} from 'react';
import {Box, Typography, IconButton, Container, Button, useMediaQuery} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {portfolioCards} from "../data/portfolio";
import PortfolioCard from "./PortfolioCard";
import {NavLink, useLocation, useParams} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import ImageCarousel from "./ImageCarousel";

const ProjectModal = ({ open, onClose, card, onCardSelect }) => {

    const {t} = useTranslation();

    const { lang } = useParams();
    const location = useLocation(); // Ottieni il path corrente
    const getPath = (path) => (lang ? `/${lang}${path}` : path);

    const isMobile = useMediaQuery('(max-width:600px)');

    if (!open) return null;

    return (
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100vw',
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1300, // Valore più alto per sovrapporsi a tutti gli altri elementi
                overflowY: 'auto'
            }}
        >
            <Container maxWidth={'md'}>
                <Box sx={{
                    display: 'flex',
                    justifyContent:'flex-end',
                    width:'100%',
                    marginTop:'5%',
                    alignItems:'center'
                }}>
                    {/* Icona per chiudere la modale */}
                    <img src={'/images/modal-close-icon.png'} alt={'close icon'}
                         onClick={onClose}
                         style={{width:'30px', cursor:'pointer'}}
                    />
                </Box>
                {/* Video */}
                <Box
                    sx={{
                        width: '100%',
                        maxWidth: '1200px',
                        aspectRatio: '16 / 9',
                        backgroundColor: 'black',
                        marginTop:'2%',
                        borderRadius:'25px',
                        position: 'relative',
                        overflow: 'hidden', // Importante per evitare overflow dello slider
                    }}
                >
                    {card?.backgroundVideo ?
                        /*<video
                            controls
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                borderRadius: '25px'
                            }}
                            poster={`/images/portfolio/progetti/${card?.thumbnailBackground.replace('.mp4', '.png')}`}
                        >
                            <source src={`/videos/portfolio/${card?.backgroundVideo}`} type="video/webm"/>
                        </video>*/
                        <iframe
                            src={`${card?.backgroundVideo}`}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                borderRadius: '25px'
                            }}
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                            allowFullScreen
                        ></iframe>
                        :
                        <ImageCarousel images={card?.images} />
                        /*<img
                            src={`/images/portfolio/progetti/${card?.background}`}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                borderRadius: '25px'
                            }}
                            alt={'background'}
                        />*/
                    }
                </Box>

                {/* Contenuto della modale */}
                <Box
                    sx={{
                        marginTop: '32px',
                        color: 'white'
                    }}
                >
                    <Typography variant="h4" gutterBottom textAlign={'left'}>
                        {card.title}
                    </Typography>
                    <Box sx={{width:isMobile ? '100%' : '70%'}}>
                        <Typography variant="body1" textAlign={'left'}>
                        <Trans
                                i18nKey={`portfolio.progetti.${card.translationKey}.innerDescription`}
                                components={{ br: <br /> }}
                            />
                        </Typography>
                    </Box>

                    {card?.clients &&
                        <Typography variant="h6" gutterBottom textAlign={'left'} sx={{marginTop: '32px'}}>
                            {t(`portfolio.clienti`)}
                        </Typography>
                    }
                    {/* Logo clienti */}
                    <Box sx={{
                        display:'flex',
                        flexDirection:'row',
                        alignItems:'center',
                        columnGap:'20px'
                    }}>
                        {card?.clients &&  card.clients.map((client) => (
                            <img src={`/images/portfolio/clients-logos/${client}`} alt={'client logo'} style={{width:'100px'}}/>
                        ))}
                    </Box>
                    <Typography variant="h6" gutterBottom textAlign={'left'} sx={{
                        marginTop: '32px'
                    }}>
                        {t(`portfolio.simili`)}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignContent: 'flex-start',
                            width: '100%',
                            marginTop: '1rem',
                            columnGap: '20px',
                            overflowX: 'auto', // Abilita lo scroll orizzontale
                            overflowY: 'hidden', // Evita lo scroll verticale
                            scrollbarWidth: 'thin', // Per i browser compatibili
                            '&::-webkit-scrollbar': { // Stile per Chrome/Safari
                                height: '8px',
                            },
                            '&::-webkit-scrollbar-thumb': { // Stile per il cursore
                                background: '#ccc',
                                borderRadius: '4px',
                            },
                        }}
                    >
                        {portfolioCards.map((card) => (
                            <PortfolioCard
                                card={card}
                                key={card.id}
                                title={card.title}
                                isSelected={card?.id === card.id}
                                onClick={() => {
                                    onCardSelect(card.id); // Informa il componente Portfolio
                                    onClose(); // Chiudi la modale
                                }}
                            />
                        ))}
                    </Box>
                    <Typography variant={'h4'} textAlign={'center'} mt={'32px'}>
                        {t(`portfolio.copy`)}
                    </Typography>
                    <Box sx={{
                        display:'flex',
                        width:'100%',
                        justifyContent:'center',
                        alignItems:'center',
                        marginTop:'32px',
                        marginBottom:'32px'
                    }}>

                        <Button component={NavLink}
                                to={getPath('/contattaci')} variant={'portfolio'}>
                            {t(`portfolio.inner-action`)}
                        </Button>
                    </Box>

                </Box>
            </Container>
        </Box>
    );
};

export default ProjectModal;
